<template>
    <div
        class="upload-image border border-dashed"
        :class="{ 'bg-white border-gray-500': !hasError, 'form-input-error': hasError }"
    >
        <div class="input-wrap" @click="$refs[refKey].click">
            <slot name="text">
                <span class="btn btn-primary inline-block">Загрузить изображение</span>
            </slot>

            <span v-if="recommendedSizes" class="ml-3">
                Рекомендуемый размер: <span class="font-medium">{{ recommendedSizes }}</span>
            </span>

            <input :ref="refKey" type="file" :accept="acceptExtensions" class="input" @change="uploadFile" />
        </div>

        <div class="preview" :class="{ active: hasImage }">
            <div v-if="hasImage" class="item">
                <slot name="image" :preview="previewImage">
                    <img :src="previewImage ? previewImage.url : modelValue?.file || modelValue?.url" alt="#" />
                </slot>
                <slot v-if="canDelete" name="remove" remove="remove">
                    <span @click="remove"><XCircleIcon /></span>
                </slot>
            </div>
        </div>

        <div v-if="modelValue" class="flex flex-col gap-6 mt-1 border-t border-[#cbd5e0] pt-4">
            <div>
                <label class="form-label">Заголовок изображения</label>
                <input
                    :value="modelValue.title"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                    @input="onChangeTitle"
                />
            </div>

            <div>
                <label class="form-label">Альт изображения</label>
                <input
                    :value="modelValue.alt"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                    @input="onChangeAlt"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UploadImageNew',
    props: {
        refKey: {
            type: String,
            default: 'uploadImageInput',
        },
        image: String,
        modelValue: null,
        delete: Boolean,
        canDelete: {
            type: Boolean,
            default: true,
        },
        recommendedSizes: {
            type: String,
            default: null,
        },
        acceptExtensions: {
            type: String,
            default: 'image/jpeg, image/png, image/gif, image/webp',
        },
        hasError: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            previewImage: null,
        };
    },
    computed: {
        hasImage() {
            return (
                this.previewImage ||
                (this.modelValue && this.modelValue.file) ||
                (this.modelValue && this.modelValue.url)
            );
        },
    },
    watch: {
        image: function (val) {
            this.previewImage = val;
        },
    },
    mounted() {
        this.fillPreviewImageOnInit();
    },
    methods: {
        fillPreviewImageOnInit() {
            if (this.image && this.image.file) {
                this.previewImage = {
                    url: window.URL.createObjectURL(this.image.file),
                };
            } else if (this.image) this.previewImage = this.image;
        },
        uploadFile(e) {
            const modelValue = this.modelValue
                ? { ...this.modelValue, file: e.target.files[0] }
                : { file: e.target.files[0] };
            this.$emit(`update:modelValue`, modelValue);
            this.previewImage = {
                url: window.URL.createObjectURL(e.target.files[0]),
            };

            setTimeout(() => {
                this.$emit(`update:delete`, false);
            }, 0);
        },
        remove() {
            this.$emit(`update:delete`, true);

            this.resetImage();

            setTimeout(() => {
                this.$emit(`update:modelValue`, null);
            }, 0);
        },

        resetFields() {
            this.$emit(`update:delete`, false);
            this.resetImage();
        },

        resetImage() {
            window.URL.revokeObjectURL(this.previewImage);

            this.previewImage = null;
            this.$refs[this.refKey].value = null;
        },

        onChangeTitle(e) {
            this.$emit(`update:modelValue`, { ...this.modelValue, title: e.target.value });
        },

        onChangeAlt(e) {
            this.$emit(`update:modelValue`, { ...this.modelValue, alt: e.target.value });
        },
    },
};
</script>

<style scoped lang="scss">
.upload-image {
    box-sizing: border-box;
    min-height: 150px;
    padding: 20px 20px;
}

.input-wrap {
    padding-bottom: 15px;
}

.input {
    visibility: hidden;
}

.preview {
    border-top: 1px solid #cbd5e0;
    height: auto;
    width: 100%;
    display: flex;

    .item {
        max-width: 100%;
        margin: 10px;
        position: relative;

        span {
            position: absolute;
            right: -5px;
            top: -5px;
            cursor: pointer;
            background-color: #fff;
            border-radius: 50%;
        }

        img {
            overflow: hidden;
            max-height: 260px;
        }
    }
}
</style>
