<template>
    <div>
        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="relative col-span-6">
                    <label for="form-title" class="form-label">
                        Название
                        <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.title.$errors.length" for="form-title" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-title"
                        v-model="form.title"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.title.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="relative col-span-6">
                    <label for="form-percent" class="form-label">
                        Коэффициент
                        <sup v-if="v$.form.percent.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.percent.$errors.length" for="form-percent" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.percent.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-percent"
                        v-model="form.percent"
                        type="number"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.percent.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="relative col-span-6">
                    <label for="form-type" class="form-label">
                        Тип
                        <sup v-if="v$.form.type.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.type.$errors.length" for="form-type" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.type.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-name"
                        v-model="form.type"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.type.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6"></div>

                <div class="relative col-span-6">
                    <label for="form-discount_title" class="form-label">
                        Заголовок скидки
                        <sup v-if="v$.form.discount_title.required" class="text-theme-6">*</sup>
                    </label>
                    <label
                        v-if="v$.form.discount_title.$errors.length"
                        for="form-discount_title"
                        class="form-input-text-error"
                    >
                        {{ getOverrideMessage(v$.form.discount_title.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-discount_title"
                        v-model="form.discount_title"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.discount_title.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-discount_description" class="form-label">Описание скидки</label>
                    <input
                        id="form-discount_description"
                        v-model="form.discount_description"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="relative col-span-6">
                    <label class="form-label">
                        Изображение скидки
                        <sup v-if="v$.form.discount_image.required" class="text-theme-6">*</sup>
                    </label>
                    <label
                        v-if="v$.form.discount_image.$errors.length"
                        for="form-discount_image"
                        class="form-image-text-error"
                    >
                        {{ getOverrideMessage(v$.form.discount_image.$errors[0].$message) }}
                    </label>
                    <UploadImageNew
                        v-model="form.discount_image"
                        recommended-sizes="490x220px"
                        :has-error="!!v$.form.discount_image.$errors.length"
                    ></UploadImageNew>
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'tariffs' }" class="btn btn-secondary w-24 mb-2 mr-2">
                        Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import UploadImageNew from '@/components/upload-image/UploadImageNew.vue';
import { formPreparation } from '@/mixins/form/form-mixin';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'Create',
    components: { UploadImageNew, Preloader },
    mixins: [errorResponse, formPreparation],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            form: {
                title: null,
                discount_title: null,
                discount_description: null,
                discount_image: null,
                percent: null,
                type: null,
                photo_url: null,
                photo_url_delete: null,
            },
        };
    },
    validations() {
        return {
            form: {
                title: { required },
                type: { required },
                discount_title: { required },
                discount_image: { required },
                percent: { required },
            },
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Тарифы / Создать');
    },
    methods: {
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            const formData = this.formPreparation({ imagesAsObject: true });
            try {
                await this.axios.post('/tariffs', formData, {});
                if (flag) {
                    this.$notify('Запись сохранена');
                } else {
                    await this.$router.push({ name: 'tariffs' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped lang="scss"></style>
